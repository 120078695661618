import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { languageSwitchItem, getCurrentLanguage } from '../../actions/locales'
import { getSupportedCA, getConnecteds, changeServiceLanguage } from '../../actions/api'
import { setListCSK, setPassiveTokenPath, setActiveTokenPath, showVisualImpairmentView, setContrast, setFontSize } from '../../actions/localStates'
import ServiceConnectionStatus from './ServiceConnectionStatus'
import Downloads from './Downloads'
import CountDownTimer from './CountDownTimer'

import Cookies from 'universal-cookie';

import logo from '../../img/sjwsa-client-logo.png'

const availableLanguege = ["uk", "en"];
const availableLanguegeValue = ["УКР", "ENG"];

const cookies = new Cookies();

class Header extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
	    	showApps: false
	    }

		this.showVisualImpairmentView = this.showVisualImpairmentView.bind(this)
		this.setContrast = this.setContrast.bind(this)

		this.setFontSizePlus = this.setFontSizePlus.bind(this)
		this.setFontSizeMinus = this.setFontSizeMinus.bind(this)
		this.setFontSizeReset = this.setFontSizeReset.bind(this)

		this.handleShowApps = this.handleShowApps.bind(this);
	    this.closeApps = this.closeApps.bind(this);

	}

	handleShowApps (event) {
		event.preventDefault();
		this.setState({
				showApps: !this.state.showApps
			}, () => {
			document.addEventListener('click', this.closeApps);
		});
	}

	closeApps(event) { 
		this.setState({ showApps: false }, () => {
			document.removeEventListener('click', this.closeApps);
		});  
	}

	showVisualImpairmentView () {
		this.props.actions.showVisualImpairmentView(!this.props.baseUrls.showVisualImpairmentView)
	}

	setContrast () {
		this.props.actions.setContrast(!this.props.baseUrls.contrast)
	}

	setFontSizePlus () {
		if (this.props.baseUrls.fontSize < 4) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize + 1)
		} else {
			this.props.actions.setFontSize(4)
		}
	}

	setFontSizeMinus () {
		if (this.props.baseUrls.fontSize > 0) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize - 1)
		} else {
			this.props.actions.setFontSize(0)
		}
	}

	setFontSizeReset () {
		this.props.actions.setFontSize(0)
	}

	componentDidMount() {
		document.title = i18next.t("serviceTitle");
		i18next.changeLanguage('uk');
		this.props.actions.getCurrentLanguage('uk');
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.connectionStatus.baseUrl !== null) {
			if (nextProps.connectionStatus.baseUrl !== this.props.connectionStatus.baseUrl) {
				this.props.actions.getSupportedCA(nextProps.connectionStatus.baseUrl)
				.then((response) => {
					this.props.actions.setListCSK(response.ca)
				})

				if (nextProps.connectionStatus.uccConnection) {
					this.props.actions.getConnecteds(nextProps.connectionStatus.baseUrl)
			    	.then((response) => {
			    		console.log(response);
			    		if (response.passiveTokenPath.length > 0) {
			    			this.props.actions.setPassiveTokenPath(response.passiveTokenPath)
			    		}
			    		if (response.activeTokenPath.length > 0) {
			    			this.props.actions.setActiveTokenPath(response.activeTokenPath)
			    		}
			    	})
			    }
			}
		} else {
			return
		}
	}

	changeLang(lang){
		this.props.actions.getCurrentLanguage(lang);
        this.props.actions.languageSwitchItem('eng', lang, availableLanguege);
        this.props.actions.changeServiceLanguage(this.props.connectionStatus.baseUrl, lang);
        document.title = i18next.t("serviceTitle");
    }

    _renderLanguageButtonLine() {

	    function options(child, index) {
	      let activeClass = (this.props.language === child ? 'btn btn-outline-info btn-sm' : 'btn btn-default btn-sm');
	      let textValue = (availableLanguegeValue[index])

	      return (
	        <button key={child} className={activeClass} style={{marginLeft: "7px"}} onClick={this.changeLang.bind(this, child)}>{textValue}</button>
	      );
	    }

	    return (
	    	<div id="languageButtonLine" className="col-md-9 col-sm-9 col-xs-9 text-right">
	    		{availableLanguege.map(options.bind(this))}
	        </div>
	    );
	}

	redirectToApp(url) {
		if (this.props.baseUrls.token !== null && this.props.baseUrls.token.length > 0) {
			cookies.set('caasJwtToken', this.props.baseUrls.token, { path: '/', expires: new Date(Date.now() + 10000), domain: this.props.baseUrls.domain });
		}

		window.location.href = url;
	}

	render() {
		let showAppsOpen = 'dropdown-menu'
		if (this.state.showApps) {
			showAppsOpen = 'dropdown-menu show'
		}

		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div>
		        	{
		        		this.props.baseUrls.showVisualImpairmentView
		        		? <div className="margin-padding-0" style={{paddingTop: "7px", paddingBottom: "7px"}}>
		        			<div className="visual-impairment">
			        			<div className="visual-impairment-container">
				        			<div className="visual-impairment-div">
				        				<div className="visual-impairment-buttons">
				        					<div className="visual-impairment-filter">
				        						<div className="visual-impairment-title">
				        							{i18next.t("contrast")}
				        						</div>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setContrast}><i class="fa fa fa-adjust"></i></button>
				        					</div>
				        					<div className="visual-impairment-panel-size">
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizePlus}><i className="fa fa-plus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeMinus}><i className="fa fa-minus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeReset}><i className="fa fa-rotate-right revert-img"></i></button>
				        					</div>
					        			</div>
					        			<div className="visual-impairment-hide">
					        				<button className="btn visual-impairment-panel-buttons" onClick={this.showVisualImpairmentView}><i className="fa fa-times"></i></button>
					        			</div>
				        			</div>
				        		</div>
			        		</div>
			        	</div>
		        		: null
		        	}
		        </div>
	
				<div className="row mtb-default">
			        <div className="col-md-1 col-sm-2 col-3 text-left">
			            <img className="img" src={logo} width="64" height="64" alt="Logo" />
			        </div>
			        <div className="col-md-7 col-sm-10 col-9 text-left text-muted">
			            <h4 className="d-none d-sm-none d-md-block" id="clientForService">{i18next.t("clientForService")}</h4>
			            <h4 style={{marginLeft: "-7px"}} className="d-none d-sm-block d-md-none" id="clientForService">{i18next.t("clientForService")}</h4>
			            <h4 className="d-block d-sm-none margin-left-20-font-size" id="clientForService">{i18next.t("clientForService")}</h4>
			            <span className="d-none d-sm-none d-md-block" id="titleCipherBis">{i18next.t(this.props.baseUrls.cipherClientTitle)}</span>
			        </div>
			        <div className="col-md-4 col-sm-12 col-xs-12 text-center text-muted">
			        	<ServiceConnectionStatus connectionStatus={this.props.connectionStatus} url={this.props.baseUrls} />
			        </div>
			    </div>
			    <div className="row mtb-default" style={{marginBottom: "0px"}}>
			        <CountDownTimer /> {this._renderLanguageButtonLine()}
			    </div>
			    <div className="row">
			    	<div className="col-lg-6 col-md-6 col-sx-12 col-xs-12">
			    		{
			    			this.props.baseUrls.authEnabled === "true" && this.props.baseUrls.isLoggedIn === true
			    			? <div>
			    				{
						    		this.props.baseUrls.apps !== undefined && this.props.baseUrls.apps.length > 0
						    		? <div className="col-lg-6 col-md-6 col-sx-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
										<i className="fa fa-ellipsis-h fa-2x" type="button" style={{color: "#888"}} onClick={this.handleShowApps} data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showApps}></i>
										<div className={showAppsOpen} style={{minWidth: "180px", maxWidth: "250px"}}>
											{
												this.props.baseUrls.apps.map((item, index) => {
													return <div className="header-span" key={index} onClick={this.redirectToApp.bind(this, item.url)} style={{textDecoration: "none", cursor: "pointer", paddingLeft: "15px"}}>{item.clientTitle}</div>
												})	
											}
										</div>
									</div>
									: null
						    	}
			    			</div>
			    			: <div>
			    				{
						    		this.props.baseUrls.authEnabled === "false" && this.props.baseUrls.apps !== undefined && this.props.baseUrls.apps.length > 0
						    		? <div className="col-lg-6 col-md-6 col-sx-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
										<i className="fa fa-ellipsis-h fa-2x" type="button" style={{color: "#888"}} onClick={this.handleShowApps} data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showApps}></i>
										<div className={showAppsOpen} style={{minWidth: "180px", maxWidth: "250px"}}>
											{
												this.props.baseUrls.apps.map((item, index) => {
													return <div className="header-span" key={index} onClick={this.redirectToApp.bind(this, item.url)} style={{textDecoration: "none", cursor: "pointer", paddingLeft: "15px"}}>{item.clientTitle}</div>
												})	
											}
										</div>
									</div>
									: null
						    	}
			    			</div>
			    		}
			    		
			    	</div>
			    	
				    <div className="col-lg-6 col-md-6 col-sx-12 col-xs-12" style={{textAlign: "right"}}>
			        	<span style={{cursor: "pointer"}} onClick={this.showVisualImpairmentView}><i class="fa fa-low-vision"></i>&nbsp;{i18next.t("visualImpairment")}</span>
			        </div>
			    </div>
			    <hr />
			    <Downloads connectionStatus={this.props.connectionStatus.status} url={this.props.baseUrls} />		    
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        localesReducer: state.localesReducer,
        language: state.localesReducer.language,
        connectionStatus: state.connectionStatusReucer,
        baseUrls: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    getCurrentLanguage,
	    getSupportedCA,
	    setListCSK,
	    getConnecteds,
	    setPassiveTokenPath,
	    setActiveTokenPath,
	    changeServiceLanguage, showVisualImpairmentView, setContrast, setFontSize
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);